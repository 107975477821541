import { getEncodedQueryString } from '@canalplus/mycanal-commons';
import type { PassSdkConfig } from '../config/types';
import { paths } from '../constants';

export type GetSignupUrlParameters = {
  /** Fulfill **idpName** parameter */
  application: string;

  /** Url where to redirect after signup */
  redirectUri: string;
};

/** Get the Pass signup url */
export const getSignupUrl = (
  { baseUrl: { oauth: oauthBaseUrl }, clientId, portailId }: PassSdkConfig,
  { application, redirectUri }: GetSignupUrlParameters,
): string => {
  const url = `${oauthBaseUrl}${paths.signup}`;

  const encodedQueryString = getEncodedQueryString({
    clientId,
    idpName: application,
    pass_target: redirectUri,
    portailId,
    scope: 'pass_profile email',
    state: `origref=${redirectUri}`,
  });

  return `${url}${encodedQueryString}`;
};

/**
 * Get the signup page URL
 *
 * @alias
 * @deprecated Use {@link getSignupUrl} instead
 */
export const getSignupLink = getSignupUrl;
